import clsx from "clsx";
import Link from "next/link";
import { ComponentProps, FC, PropsWithChildren, ReactNode } from "react";

interface AnchorProps extends ComponentProps<"a"> {
  href: string;
  variant?: "link" | "contained";
}

const Anchor: FC<AnchorProps> = (props) => {
  const {
    href,
    variant = "link",
    className,
    children,
    ...remaingingProps
  } = props;
  return (
    <AnchorWrap
      condition={props.target !== "_blank"}
      wrap={(children: ReactNode) => (
        <Link href={href} passHref legacyBehavior>
          {children}
        </Link>
      )}
    >
      <a
        href={props.target === "_blank" ? href : undefined}
        className={clsx(
          className,
          "focus:outline-none motion-safe:transition",
          {
            "text-blue hover:underline focus:underline ": variant === "link",
            "inline-block rounded-xl bg-accent px-4 py-2 text-white hover:bg-accent-dark focus:bg-accent-dark focus:outline-none":
              variant === "contained",
          },
        )}
        {...remaingingProps}
      >
        {children}
      </a>
    </AnchorWrap>
  );
};

interface AnchorWrapProps extends PropsWithChildren {
  condition: boolean;
  wrap?: (children: ReactNode) => ReactNode;
}

const AnchorWrap: FC<AnchorWrapProps> = ({ condition, wrap, children }) =>
  condition && wrap ? wrap(children) : children;

export default Anchor;
