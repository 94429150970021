import React, { forwardRef, useEffect, useRef } from "react";
import useConstants from "../../hooks/useConstants";
import FooterMountains from "../ui/FooterMountains";
import Anchor from "../utilities/Anchor";
import SocialMedia from "../utilities/SocialMedia";
import Typography from "../utilities/Typography";

const Footer = forwardRef<HTMLElement>(function Footer(props, ref) {
  const footerImage = useRef<HTMLDivElement>(null);
  const { companyName, phone, email, address } = useConstants();

  useEffect(() => {
    const setFooterImageHeight = () => {
      if (footerImage && footerImage.current) {
        document.documentElement.style.setProperty(
          "--footerImage-y",
          footerImage.current?.offsetHeight - 10 + "px",
        );
      }
    };

    setFooterImageHeight();

    window.addEventListener("resize", setFooterImageHeight);

    return () => {
      window.removeEventListener("resize", setFooterImageHeight);

      document.documentElement.style.removeProperty("--footerImage-y");
    };
  }, []);

  return (
    <footer className="relative" ref={ref}>
      <FooterMountains ref={footerImage} />

      <div className="container relative z-10 space-y-4 pb-8 lg:grid lg:max-w-screen-lg lg:grid-cols-3 lg:gap-8 lg:space-y-0">
        <div className="text-left lg:flex lg:justify-center">
          <div>
            <Typography
              variant="paragraph-primary"
              className="text-lg"
              component="p"
            >
              Verkennen
            </Typography>

            <ul>
              <li>
                <Anchor href="/diensten/webdevelopment">Webdevelopment</Anchor>
              </li>
              <li>
                <Anchor href="/diensten/marketing">Marketing</Anchor>
              </li>
              <li>
                <Anchor href="/diensten/design">Design</Anchor>
              </li>
              <li>
                <Anchor href="/over-ons">Over Ons</Anchor>
              </li>
              <li>
                <Anchor href="/projecten">Projecten</Anchor>
              </li>
              <li>
                <Anchor href="/contact">Contact</Anchor>
              </li>
              <li>
                <Anchor href="/privacy">Privacy</Anchor>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-left lg:flex lg:justify-center">
          <div>
            <Typography
              variant="paragraph-primary"
              className="text-lg"
              component="p"
            >
              Volg ons
            </Typography>
            <SocialMedia />
          </div>
        </div>
        <div className="text-left lg:flex lg:justify-center">
          <div>
            <Typography
              variant="paragraph-primary"
              className="text-lg"
              component="p"
            >
              Contact
            </Typography>
            <Typography variant="paragraph" size="text-base">
              {address.street} {address.number}
              {address.numberAddition} <br /> {address.postCode} {address.city}
            </Typography>

            <ul>
              <li>
                <Anchor href={`mailto:${email}`}>{email}</Anchor>
              </li>
              <li>
                <Anchor href={`tel:${phone.html}`}>{phone.default}</Anchor>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container flex items-center justify-center pb-2 text-center">
        <Typography
          variant="paragraph"
          color="text-gray-dark"
          size="text-xs"
          className="inline-flex border-t border-primary pt-2"
        >
          © {new Date().getFullYear()} {companyName}. Alle rechten
          voorbehouden. Deze website wordt beheerd door {companyName}
        </Typography>
      </div>
    </footer>
  );
});

export default Footer;
