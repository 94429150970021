import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { AnimatePresence, motion, SVGMotionProps } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, forwardRef, PropsWithChildren, useEffect, useState } from "react";
import useConstants from "../../hooks/useConstants";
import imageLogo from "../../public/general/logo.png";
import Typography from "../utilities/Typography";

const Header = forwardRef<HTMLElement>(function Header(props, ref) {
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouter();
  const { companyName } = useConstants();

  useEffect(() => {
    window.addEventListener("scroll", () => setMenuOpen(false));
    return () => window.removeEventListener("scroll", () => setMenuOpen(false));
  });

  useEffect(() => {
    router.events.on("routeChangeComplete", () => setMenuOpen(false));

    return () =>
      router.events.off("routeChangeComplete", () => setMenuOpen(false));
  }, [router.events]);

  return (
    <header className="sticky top-0 z-50 bg-white shadow-md" ref={ref}>
      <div className="container flex items-center justify-between py-2">
        <Link href="/" passHref legacyBehavior>
          <a
            className="flex flex-shrink-0 items-center justify-center space-x-4"
            title="Homepagina"
          >
            <Image
              src={imageLogo}
              alt={`Logo ${companyName}`}
              priority
              quality={100}
            />

            <Typography
              variant="paragraph-primary"
              size="text-2xl"
              component="span"
            >
              {companyName}
            </Typography>
          </a>
        </Link>
        <div>
          <ul className="hidden md:flex">
            <Dialog.Root open={menuOpen} onOpenChange={setMenuOpen}>
              <ListItem>
                <Dialog.Trigger
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="hover:underline focus:outline-none focus-visible:underline"
                >
                  <Typography
                    variant="paragraph-primary"
                    size="text-2xl"
                    component="span"
                  >
                    Diensten
                  </Typography>
                </Dialog.Trigger>
              </ListItem>

              <Dialog.Content
                aria-label="Subcategorieën van Diensten"
                className="absolute top-[var(--header-height)] z-10 inline-flex flex-col space-y-2 bg-white p-4 shadow-md focus:outline-none"
              >
                <MenuItem
                  href="/diensten/webdevelopment"
                  title="Webdevelopment"
                />
                <MenuItem href="/diensten/marketing" title="Marketing" />
                <MenuItem href="/diensten/design" title="Design" />
              </Dialog.Content>
            </Dialog.Root>

            <ListItem>
              <MenuItem href="/over-ons" title="Over ons" />
            </ListItem>
            <ListItem>
              <MenuItem href="/projecten" title="Projecten" />
            </ListItem>
            <ListItem>
              <MenuItem href="/contact" title="Contact" />
            </ListItem>
          </ul>

          <SideDialog />
        </div>
      </div>
    </header>
  );
});

export default Header;

type PathProps = SVGMotionProps<SVGPathElement>;

const Path: FC<PathProps> = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

const SideDialog = () => {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", () => setOpen(false));

    return () => router.events.off("routeChangeComplete", () => setOpen(false));
  }, [router.events]);

  return (
    <div className="flex scale-[1.39125] md:hidden">
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger title={open ? "Sluit menu" : "Open menu"}>
          <motion.svg
            initial={false}
            animate={open ? "open" : "closed"}
            width="23"
            height="23"
            viewBox="0 0 23 23"
          >
            <Path
              variants={{
                closed: { d: "M 2 2.5 L 20 2.5" },
                open: { d: "M 3 16.5 L 17 2.5" },
              }}
            />
            <Path
              d="M 2 9.423 L 20 9.423"
              variants={{
                closed: { opacity: 1 },
                open: { opacity: 0 },
              }}
              transition={{ duration: 0.1 }}
            />
            <Path
              variants={{
                closed: { d: "M 2 16.346 L 20 16.346" },
                open: { d: "M 3 2.5 L 17 16.346" },
              }}
            />
          </motion.svg>
        </Dialog.Trigger>

        <AnimatePresence>
          {open && (
            <Dialog.Portal forceMount>
              <Dialog.Overlay forceMount />
              <Dialog.Content forceMount>
                <motion.div
                  className={clsx(
                    "fixed bottom-0 left-0 z-50 h-full max-h-[calc(100vh-var(--header-height))] w-screen bg-white shadow-md focus:outline-none ",
                  )}
                  initial={{ x: "-100vw" }}
                  animate={{ x: 0 }}
                  exit={{ x: "-100vw" }}
                  transition={{ duration: 0.15 }}
                >
                  <div className="inline-flex h-full max-h-[calc(100vh-(var(--header-height)*2))] w-full flex-col items-center justify-center">
                    <ul className="space-y-2 text-center">
                      <li>
                        <MenuItem
                          href="/diensten/webdevelopment"
                          title="Webdevelopment"
                          variant="mobile"
                        />
                      </li>
                      <li>
                        <MenuItem
                          href="/diensten/marketing"
                          title="Marketing"
                          variant="mobile"
                        />
                      </li>
                      <li>
                        <MenuItem
                          href="/diensten/design"
                          title="Design"
                          variant="mobile"
                        />
                      </li>
                      <li>
                        <MenuItem
                          href="/over-ons"
                          title="Over ons"
                          variant="mobile"
                        />
                      </li>
                      <li>
                        <MenuItem
                          href="/projecten"
                          title="Projecten"
                          variant="mobile"
                        />
                      </li>
                      <li>
                        <MenuItem
                          href="/contact"
                          title="Contact"
                          variant="mobile"
                        />
                      </li>
                    </ul>
                  </div>
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          )}
        </AnimatePresence>
      </Dialog.Root>
    </div>
  );
};

const ListItem: FC<PropsWithChildren> = ({ children }) => {
  return <li className="mx-4 first:ml-0 last:mr-0">{children}</li>;
};

interface MenuItemProps {
  href: string;
  title: string;
  variant?: "mobile" | "desktop";
}

const MenuItem: FC<MenuItemProps> = ({ href, title, variant = "desktop" }) => {
  return (
    <Link href={href} passHref title={title}>
      <Typography
        variant="paragraph-primary"
        size={variant === "desktop" ? "text-2xl" : "text-3xl"}
        className="hover:underline focus:outline-none focus-visible:underline"
        component="span"
      >
        {title}
      </Typography>
    </Link>
  );
};
