import { useMemo } from "react";
import useConstants from "./useConstants";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const useSocialMedia = () => {
  const { socialMedia } = useConstants();

  const socialMediaData = useMemo(
    () => ({
      facebook: {
        title: "Facebook",
        url: socialMedia.facebook,
        icon: faFacebook,
      },
      instagram: {
        title: "Instagram",
        url: socialMedia.instagram,
        icon: faInstagram,
      },
      linkedin: {
        title: "Linkedin",
        url: socialMedia.linkedin,
        icon: faLinkedin,
      },
    }),
    [socialMedia],
  );

  return socialMediaData;
};

export default useSocialMedia;
