import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentProps, FC } from "react";
import useSocialMedia from "../../hooks/useSocialMedia";
import Anchor from "./Anchor";

const SocialMedia: FC<ComponentProps<"ul">> = (props) => {
  const socialMediaData = useSocialMedia();

  return (
    <ul {...props}>
      {Object.values(socialMediaData).map((social) => (
        <li key={social.title}>
          <Anchor
            href={social.url}
            title={social.title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={social.icon} fixedWidth className="mr-2" />
            {social.title}
          </Anchor>
        </li>
      ))}
    </ul>
  );
};

export default SocialMedia;
