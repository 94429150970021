import clsx from "clsx";
import { FC, PropsWithChildren } from "react";

const GradientLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <main
      role="main"
      id="main-content"
      className={clsx(
        "min-h-[calc(100vh-var(--body-y))] bg-primary-light bg-gradient-to-b from-white to-primary-light pb-[calc(var(--footerImage-y)*0.5)]",
      )}
    >
      {children}
    </main>
  );
};

export default GradientLayout;
